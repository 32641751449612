try {
    window.andon_job_status = {
        IDLE: 0,
        START: 1,
        HOLD: 2,
        SETUP: 3,
        STOP: 4,
        FINISH: 5,
        // : 6,
        // : 7,
        // : 8,
        NOT_FOUND: 9,
    };

    window.andon_job_method = {
        job_card: "job_card",
        work_order: "work_order",
    };

    require("./bootstrap");
    require("./remote");
    require("./user");
    require("./production_line");
    require("./workstation");
    require("./realtime_counter");
    require("./dashboard");
    require("./runtime");
    require("./inspections");
    require("./inspection_chart");
    require("./echart");
    require("./runtime_view");
    require("./runtime_detail");
    require("./inspection_view");
    require("./inspection_detail");
    require("./inspection_show");
    require("./inspection_history");
} catch (e) {}

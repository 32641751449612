window.andon_inspections = {};
$(document).ready(function () {
    let $page = $("#inspections-page");

    andon_inspections = {
        init: function () {
            $table = $page.find("#inspections-datatable");
            this.dtTable = $table.DataTable({
                processing: true,
                serverSide: true,
                searching: false,
                paging: false,
                lengthChange: false,
                ajax: {
                    url: "/ajax/inspections",
                    type: "get",
                    data: function (d) {
                        d.mode = "inspections_datatable";
                        d.keyword = $("#filter-keyword").val();
                        d.date = $("#filter-date").val();
                        d.status = $("#filter-status").val();
                    },
                },
                columns: [
                    { data: "id", name: "id" },
                    { data: "workstation_name", name: "workstation_name" },
                    { data: "created_at_display", name: "created_at_display" },
                    { data: "state_display", name: "state_display" },
                    { data: "action", name: "action" },
                ],
                columnDefs: [
                    { targets: "no-sort", orderable: false },
                    { targets: "text-center", className: "text-center" },
                    { targets: "text-end", className: "text-end" },
                    { targets: "text-end", className: "text-end" },
                ],
            });

            $table.on("click", ".btn-finish", function (e) {
                e.preventDefault();
                rowId = $(this).attr("data-id");
                helper.okConfirm(
                    "Are you sure to COMPLETE this data?",
                    andon_inspections.finishData,
                    rowId
                );
            });

            $("#filter-date").datepicker({
                format: "d MM yyyy",
                autoclose: true,
                todayHighlight: true,
            });

            $("#filter-date").on("change", function (e) {
                if ($(this).val() != "") {
                    $("#filter-date-clear").show();
                }
                andon_inspections.dtTable.draw();
            });

            $("#filter-date-clear").on("click", function (e) {
                $("#filter-date").val("");
                $("#filter-date-clear").hide();
                andon_inspections.dtTable.draw();
            });

            $("#filter-status").on("change", function (e) {
                andon_inspections.dtTable.draw();
            });

            $("#filter-keyword").on("keydown", function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    andon_inspections.dtTable.draw();
                }
            });

            $("#btn-filter").on("click", function (e) {
                andon_inspections.dtTable.draw();
            });

            let date = new Date();
            let today = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            );
            $("#filter-date").datepicker("setDate", today);
            andon_inspections.dtTable.draw();
        },

        finishData: function (rowId) {
            $.ajax({
                url: "/inspections/" + rowId + "/finish",
                type: "PUT",
                dataType: "json",
                success: function (response) {
                    andon_inspections.dtTable.draw();
                    if (response.status) {
                        helper.setAlert(response.message, "success");
                    } else {
                        helper.setAlert(response.message, "warning");
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },

        refresh: function () {
            andon_inspections.dtTable.draw();
        },
    };

    if ($page.length) {
        andon_inspections.init();
    }
});

var production_line = {};
$(document).ready(function () {
    let $page = $('#production_line-page'),
        $table = {};

    production_line = {
        dtTable: {},
        
        init: function () {
            $table = $page.find('#production_line-datatable');
            this.dtTable = $table.DataTable({
                "processing": true,
                "serverSide": true,
                "searching": false,
                "lengthChange": false,
                "ajax": {
                    url: "/ajax/production_line",
                    type: "POST",
                    data: function (d) { 
                        d.mode = 'datatable';
                        d.status = $("#filter-status").val();
                        d.keyword = $("#filter-keyword").val();
                    }
                },
                "columns": [
                    { data: 'id', name: 'id' },
                    { data: 'name', name: 'name' },
                    { data: 'status', name: 'status' },
                    { data: 'status_display', name: 'status_display' },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'created_at_display', name: 'created_at_display' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'updated_at_display', name: 'updated_at_display' },
                    { data: 'action', name: 'action' }
                ],
                "columnDefs": [
                    { targets: 'no-sort', orderable: false },
                    { targets: 'hidden', visible: false },
                    { targets: 'text-center', className: 'text-center' },
                    { targets: 'text-end', className: 'text-end' },
                    { targets: 'wrapper-action', className: 'wrapper-action' },
                    { targets: [ 3 ], orderData: [ 2 ] },
                    { targets: [ 5 ], orderData: [ 4 ] },
                    { targets: [ 7 ], orderData: [ 6 ] },
                ],
            });

            $table.on('click', '.btn-delete', function (e) {
                e.preventDefault();
                rowId = $(this).attr('data-id');
                helper.deleteConfirm('Are you sure to delete this data?', production_line.deleteData, rowId);
            });

            $("#filter-status").on('change', function (e) {
                production_line.dtTable.draw();
            });

            $('#filter-keyword').on('keydown', function(e) {
                if (e.which == 13) {
                    e.preventDefault();
                    production_line.dtTable.draw();
                }
            });

            $("#btn-filter").on('click', function (e) {
                production_line.dtTable.draw();
            });
        },

        deleteData: function (rowId) {
            $.ajax({
                url: '/production_line/'+rowId,
                type: 'DELETE',
                dataType: 'json',
                success: function(response) {
                    production_line.dtTable.draw();
                    if (response.status) {
                        helper.setAlert(response.message, 'success');
                    } else {
                        helper.setAlert(response.message, 'warning');
                    }
                },
                error: function(response) {
                    console.log(response);
                }
            });
        }

    };

    if ($page.length) {
        production_line.init();
    } 
});
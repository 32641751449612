window.andon_countUp = [];
window.andon_realtime_counter = {};
$(document).ready(function () {
    andon_realtime_counter = {
        refresh: function (data = null) {
            if (data !== null && data !== false) {
                if (andon_countUp[data.id]) {
                    andon_realtime_counter.clear(data.id);
                }
                let now = new Date();
                let milliSecond = now.getTime() - data.latest_time;

                andon_countUp[data.id] = setInterval(function () {
                    milliSecond += 1000;

                    data.count = {
                        id: data.id,
                        diff: milliSecond,
                    };

                    if ($("#dashboard_sub-page").length) {
                        andon_dashboard.refresh_part(data);
                    }
                    if ($("#runtime_view-page").length) {
                        andon_runtime_view.refresh_part(data);
                    }
                    if ($("#runtime_detail-page").length) {
                        andon_runtime_detail.refresh_part(data);
                    }
                }, 1000);
            }
        },

        clear: function (id = null) {
            if (id !== null && id !== false) {
                if (andon_countUp[id]) {
                    clearInterval(andon_countUp[id]);
                }
            }
        },
    };
});

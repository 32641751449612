window.andon_runtime_view = {};
$(document).ready(function () {
    andon_runtime_view = {
        init: function () {
            andon_chart.initStatusRecord();
            andon_chart.updateStatusRecord();
            andon_chart.generateActualRecordChart("init");
            andon_chart.generateActualNGRecordChart("init");

            andon_runtime_view.refresh_counter();
        },

        refresh_counter: function () {
            let register_log_id = window.location.pathname.split("/")[2];
            let data = {
                id: register_log_id,
                latest_time: $("#rl_production_time_" + register_log_id).attr(
                    "data-latest"
                ),
            };

            andon_realtime_counter.refresh(data);
        },

        refresh: function ($data = null) {
            console.log("Refresh data", $data);
            if ($data !== null && $data !== false) {
                let data = $.parseJSON($data);

                $("#rl_workstation_name_uid_" + data.register_log.id).text(
                    data.workstation.name + " - " + data.workstation.uid
                );
                $("#rl_employee_name_" + data.register_log.id).text(
                    data.register_log.employee_name
                );
                $("#rl_job_id_" + data.register_log.id).text(
                    data.register_log.job_id
                );

                $("#rl_performance_" + data.register_log.id).text(
                    helper.round(data.register_log.performance, 2) + "%"
                );
                $("#rl_average_cycle_time_" + data.register_log.id).text(
                    helper.round(
                        data.register_log.average_cycle_time / 1000,
                        2
                    ) + " seconds"
                );
                $("#rl_average_cycle_time_" + data.register_log.id).attr(
                    "data-value",
                    helper.round(data.register_log.average_cycle_time, 2)
                );
                $("#rl_availability_" + data.register_log.id).text(
                    helper.round(data.register_log.availability, 2) + "%"
                );

                let run_time = new Date(0);
                run_time.setMilliseconds(data.register_log.run_production_time);
                let run_time_string = run_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_run_time_" + data.register_log.id).text(run_time_string);
                $("#rl_run_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.run_production_time
                );
                let run_time_percentage = data.register_log.total_time
                    ? helper.round(
                          (data.register_log.run_production_time /
                              data.register_log.total_time) *
                              100,
                          2
                      )
                    : 0;
                $("#rl_run_percentage_" + data.register_log.id).attr(
                    "title",
                    helper.round(run_time_percentage, 2) + "%"
                );
                $("#rl_run_time_percentage_" + data.register_log.id).width(
                    helper.round(run_time_percentage, 2) + "%"
                );
                let hold_time = new Date(0);
                hold_time.setMilliseconds(data.register_log.hold_time);
                let hold_time_string = hold_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_hold_time_" + data.register_log.id).text(
                    hold_time_string
                );
                $("#rl_hold_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.hold_time
                );
                let hold_time_percentage = data.register_log.total_time
                    ? helper.round(
                          (data.register_log.hold_time /
                              data.register_log.total_time) *
                              100,
                          2
                      )
                    : 0;
                $("#rl_hold_percentage_" + data.register_log.id).attr(
                    "title",
                    helper.round(hold_time_percentage, 2) + "%"
                );
                $("#rl_hold_time_percentage_" + data.register_log.id).width(
                    helper.round(hold_time_percentage, 2) + "%"
                );
                let setup_time = new Date(0);
                setup_time.setMilliseconds(data.register_log.setup_time);
                let setup_time_string = setup_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_setup_time_" + data.register_log.id).text(
                    setup_time_string
                );
                $("#rl_setup_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.setup_time
                );
                let setup_time_percentage = data.register_log.total_time
                    ? helper.round(
                          (data.register_log.setup_time /
                              data.register_log.total_time) *
                              100,
                          2
                      )
                    : 0;
                $("#rl_setup_percentage_" + data.register_log.id).attr(
                    "title",
                    helper.round(setup_time_percentage, 2) + "%"
                );
                $("#rl_setup_time_percentage_" + data.register_log.id).width(
                    helper.round(setup_time_percentage, 2) + "%"
                );
                let stop_time = new Date(0);
                stop_time.setMilliseconds(data.register_log.stop_time);
                let stop_time_string = stop_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_stop_time_" + data.register_log.id).text(
                    stop_time_string
                );
                $("#rl_stop_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.stop_time
                );
                let stop_time_percentage = data.register_log.total_time
                    ? helper.round(
                          (data.register_log.stop_time /
                              data.register_log.total_time) *
                              100,
                          2
                      )
                    : 0;
                $("#rl_stop_percentage_" + data.register_log.id).attr(
                    "title",
                    helper.round(stop_time_percentage, 2) + "%"
                );
                $("#rl_stop_time_percentage_" + data.register_log.id).width(
                    helper.round(stop_time_percentage, 2) + "%"
                );

                $("#rl_target_" + data.register_log.id).text(
                    data.register_log.target
                );
                $("#rl_actual_" + data.register_log.id).text(
                    data.register_log.actual
                );
                $("#rl_actual_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.actual
                );
                $("#_rl_actual_" + data.register_log.id).text(
                    data.register_log.actual
                );
                $("#_rl_actual_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.actual
                );
                $("#rl_actual_ng_" + data.register_log.id).text(
                    data.register_log.actual_ng
                );
                $("#rl_actual_ng_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.actual_ng
                );
                let progress_percentage = data.register_log.target
                    ? helper.round(
                          (data.register_log.actual /
                              data.register_log.target) *
                              100,
                          2
                      )
                    : 0;
                $("#rl_progress_percentage_" + data.register_log.id).text(
                    helper.round(progress_percentage, 2) + "%"
                );
                $("#rl_progress_bar_" + data.register_log.id).width(
                    helper.round(progress_percentage, 2) + "%"
                );

                let rl_bg_color = "";
                let rl_toggle_icon = "";
                let rl_job_status = "";
                switch (parseInt(data.data_value.job_status)) {
                    case andon_job_status.IDLE:
                        rl_bg_color = "bg-info";
                        rl_toggle_icon = "fa-toggle-on";
                        rl_job_status = "Idle";
                        break;
                    case andon_job_status.START:
                        rl_bg_color = "bg-success";
                        rl_toggle_icon = "fa-toggle-on";
                        rl_job_status = "RUN";
                        break;
                    case andon_job_status.HOLD:
                        rl_bg_color = "bg-secondary";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Hold";
                        break;
                    case andon_job_status.SETUP:
                        rl_bg_color = "bg-warning";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Setup";
                        break;
                    case andon_job_status.STOP:
                        rl_bg_color = "bg-danger";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Stop";
                        break;
                    case andon_job_status.FINISH:
                        rl_bg_color = "bg-primary";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Complete";
                        break;
                }

                let icon_color = $("#rl_bg_color_" + data.register_log.id);
                icon_color.removeClass(
                    "bg-info bg-success bg-secondary bg-warning bg-danger"
                );
                icon_color.addClass(rl_bg_color);
                let icon_logo = $("#rl_toggle_icon_" + data.register_log.id);
                icon_logo.removeClass("fa-toggle-on fa-toggle-off");
                icon_logo.addClass(rl_toggle_icon);

                let job_status = $("#rl_job_status_" + data.register_log.id);
                job_status.text(rl_job_status);
                job_status.attr("data-value", data.data_value.job_status);

                if (
                    parseInt(data.data_value.job_status) ===
                        andon_job_status.FINISH ||
                    parseInt(data.register_log.status) ===
                        andon_job_status.FINISH
                ) {
                    job_status.removeClass("custom-blink");
                }

                let total_time = new Date(0);
                total_time.setMilliseconds(data.register_log.total_time); // or run_production_time?
                let total_time_string = total_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_production_time_" + data.register_log.id).text(
                    total_time_string
                );
                $("#rl_production_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.total_time
                );
                $("#rl_production_time_" + data.register_log.id).attr(
                    "data-latest",
                    data.data_value.server_time
                );

                andon_chart.updateStatusRecord();
                andon_chart.generateActualRecordChart("update");
                andon_chart.generateActualNGRecordChart("update");

                andon_runtime_view.refresh_counter();
            }
        },

        refresh_part: function (data = null) {
            let job_status = parseInt(
                $("#rl_job_status_" + data.count.id).attr("data-value")
            );

            if (
                data !== null &&
                data !== false &&
                job_status !== andon_job_status.FINISH
            ) {
                let old_run_production_time = $(
                    "#rl_run_time_" + data.count.id
                ).attr("data-value")
                    ? parseInt(
                          $("#rl_run_time_" + data.count.id).attr("data-value")
                      )
                    : 0;

                let new_run_production_time = old_run_production_time;

                let old_hold_time = $("#rl_hold_time_" + data.count.id).attr(
                    "data-value"
                )
                    ? parseInt(
                          $("#rl_hold_time_" + data.count.id).attr("data-value")
                      )
                    : 0;
                let new_hold_time = old_hold_time;

                let old_setup_time = $("#rl_setup_time_" + data.count.id).attr(
                    "data-value"
                )
                    ? parseInt(
                          $("#rl_setup_time_" + data.count.id).attr(
                              "data-value"
                          )
                      )
                    : 0;

                let new_setup_time = old_setup_time;

                let old_stop_time = $("#rl_stop_time_" + data.count.id).attr(
                    "data-value"
                )
                    ? parseInt(
                          $("#rl_stop_time_" + data.count.id).attr("data-value")
                      )
                    : 0;
                let new_stop_time = old_stop_time;

                switch (job_status) {
                    case andon_job_status.START:
                        let actual = $("#rl_actual_" + data.count.id).attr(
                            "data-value"
                        )
                            ? parseInt(
                                  $("#rl_actual_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let actual_ng = $(
                            "#rl_actual_ng_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $("#rl_actual_ng_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;

                        let run_production_time = $(
                            "#rl_run_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $("#rl_run_time_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;

                        let old_average_cycle_time = $(
                            "#rl_average_cycle_time_" + data.count.id
                        ).attr("data-value")
                            ? Number(
                                  $(
                                      "#rl_average_cycle_time_" + data.count.id
                                  ).attr("data-value")
                              )
                            : 0;
                        let new_average_cycle_time = actual
                            ? helper.round(
                                  (run_production_time + data.count.diff) /
                                      1000 /
                                      actual,
                                  2
                              )
                            : helper.round(old_average_cycle_time / 1000, 2);
                        $("#rl_average_cycle_time_" + data.count.id).text(
                            new_average_cycle_time + " seconds"
                        );

                        new_run_production_time += data.count.diff;

                        let run_time = new Date(0);
                        run_time.setMilliseconds(new_run_production_time);
                        let run_time_string = run_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");

                        $("#rl_run_time_" + data.count.id).text(
                            run_time_string
                        );
                        break;
                    case andon_job_status.HOLD:
                        new_hold_time += data.count.diff;
                        let hold_time = new Date(0);
                        hold_time.setMilliseconds(new_hold_time);
                        let hold_time_string = hold_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_hold_time_" + data.count.id).text(
                            hold_time_string
                        );
                        break;
                    case andon_job_status.SETUP:
                        new_setup_time += data.count.diff;
                        let setup_time = new Date(0);
                        setup_time.setMilliseconds(new_setup_time);
                        let setup_time_string = setup_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_setup_time_" + data.count.id).text(
                            setup_time_string
                        );
                        break;
                    case andon_job_status.STOP:
                        new_stop_time += data.count.diff;
                        let stop_time = new Date(0);
                        stop_time.setMilliseconds(new_stop_time);
                        let stop_time_string = stop_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_stop_time_" + data.count.id).text(
                            stop_time_string
                        );
                        break;
                }

                let old_total_time = $(
                    "#rl_production_time_" + data.count.id
                ).attr("data-value")
                    ? parseInt(
                          $("#rl_production_time_" + data.count.id).attr(
                              "data-value"
                          )
                      )
                    : 0;

                let new_total_time = old_total_time + data.count.diff;

                let run_time_percentage = helper.round(
                    (new_run_production_time / new_total_time) * 100,
                    2
                );
                $("#rl_run_percentage_" + data.count.id).attr(
                    "title",
                    helper.round(run_time_percentage, 2) + "%"
                );
                $("#rl_run_time_percentage_" + data.count.id).width(
                    helper.round(run_time_percentage, 2) + "%"
                );

                let hold_time_percentage = helper.round(
                    (new_hold_time / new_total_time) * 100,
                    2
                );
                $("#rl_hold_percentage_" + data.count.id).attr(
                    "title",
                    helper.round(hold_time_percentage, 2) + "%"
                );
                $("#rl_hold_time_percentage_" + data.count.id).width(
                    helper.round(hold_time_percentage, 2) + "%"
                );

                let setup_time_percentage = helper.round(
                    (new_setup_time / new_total_time) * 100,
                    2
                );
                $("#rl_setup_percentage_" + data.count.id).attr(
                    "title",
                    helper.round(setup_time_percentage, 2) + "%"
                );
                $("#rl_setup_time_percentage_" + data.count.id).width(
                    helper.round(setup_time_percentage, 2) + "%"
                );

                let stop_time_percentage = helper.round(
                    (new_stop_time / new_total_time) * 100,
                    2
                );
                $("#rl_stop_percentage_" + data.count.id).attr(
                    "title",
                    helper.round(stop_time_percentage, 2) + "%"
                );
                $("#rl_stop_time_percentage_" + data.count.id).width(
                    helper.round(stop_time_percentage, 2) + "%"
                );

                let total_time = new Date(0);
                total_time.setMilliseconds(new_total_time); // or new_run_production_time?
                let total_time_string = total_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");

                $("#rl_production_time_" + data.count.id).text(
                    total_time_string
                );
            }
        },
    };

    if ($("#runtime_view-page").length) {
        andon_runtime_view.init();
    }
});

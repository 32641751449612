const { message } = require("laravel-mix/src/Log");

var remote = {};
$(document).ready(function () {
    remote = {
        init: function () {
            console.log("Remote init");
            mqttClient.subscribe(process.env.MIX_MQTT_TOPIC_PUB_START);
            mqttClient.subscribe(process.env.MIX_MQTT_TOPIC_SUB_SEND);
            mqttClient.subscribe(process.env.MIX_MQTT_TOPIC_SUB_SEND_NG);
            if (process.env.MIX_MQTT_METHOD === andon_job_method.work_order) {
                mqttClient.subscribe(process.env.MIX_MQTT_TOPIC_SUB_SELECTED);
            }
            mqttClient.on("message", function (topic, message) {
                console.log([topic, message].join(": "));
                //remote.machineCounter(topic, message.toString());
                remote.machineChecker(topic, message.toString());
                //mqttClient.end();
            });
        },

        machineChecker: function (topic, message) {
            setTimeout(function () {
                let url = window.location.origin + "/ajax/main";
                let newmessage = JSON.parse(message);
                let formattedMessage = message;
                if (topic == process.env.MIX_MQTT_TOPIC_PUB_START) {
                    formattedMessage = JSON.stringify({
                        MESIN_ID: newmessage.mesin_id,
                        RF_ID: "",
                    });
                }

                $.ajax({
                    url: url,
                    dataType: "json",
                    data: {
                        topic: topic,
                        message: formattedMessage,
                    },
                    success: function (response) {
                        console.log("Remote machine checker:", response);
                        if (
                            response.topic !== false &&
                            response.data !== false
                        ) {
                            let data = $.parseJSON(response.data);
                            switch (response.topic) {
                                case process.env.MIX_MQTT_TOPIC_SUB_MACHINE:
                                    if (
                                        process.env.MIX_MQTT_METHOD ===
                                            andon_job_method.work_order &&
                                        data.data_value.job_status !==
                                            andon_job_status.FINISH
                                    ) {
                                        break;
                                    }
                                case process.env.MIX_MQTT_TOPIC_PUB_START:
                                case process.env.MIX_MQTT_TOPIC_SUB_SELECTED:
                                case process.env.MIX_MQTT_TOPIC_SUB_SEND:
                                    if ($("#dashboard-page").length) {
                                        andon_dashboard.refresh(
                                            response.data,
                                            false,
                                            response.topic
                                        );
                                    }
                                    if ($("#dashboard_sub-page").length) {
                                        andon_dashboard.refresh(
                                            response.data,
                                            true,
                                            response.topic
                                        );
                                    }
                                case process.env.MIX_MQTT_TOPIC_SUB_SEND_NG:
                                    if (response.is_inspection) {
                                        if ($("#inspections-page").length) {
                                            andon_inspections.refresh(
                                                response.data
                                            );
                                        }
                                        if ($("#inspection_view-page").length) {
                                            andon_inspection_view.refresh(
                                                response.data
                                            );
                                        }
                                        if ($("#inspection_show-page").length) {
                                            andon_inspection_show.refresh(
                                                response.data
                                            );
                                        }
                                        if (
                                            $("#inspection_history-page").length
                                        ) {
                                            andon_inspection_history.refresh(
                                                response.data
                                            );
                                        }
                                    } else {
                                        if ($("#runtime-page").length) {
                                            andon_runtime.refresh(
                                                response.data
                                            );
                                        }
                                        if ($("#runtime_view-page").length) {
                                            andon_runtime_view.refresh(
                                                response.data
                                            );
                                        }
                                        if ($("#runtime_detail-page").length) {
                                            andon_runtime_detail.refresh(
                                                response.data
                                            );
                                        }
                                    }
                                    break;
                            }

                            if (response.skipNotif) {
                                return;
                            }

                            if (response.is_inspection) {
                                switch (parseInt(data.inspection.state)) {
                                    case andon_job_status.START:
                                        helper.setNotif(
                                            "Inspection",
                                            `${data.workstation.name} - ${data.workstation.uid} is started`,
                                            "success"
                                        );
                                        break;
                                    case andon_job_status.FINISH:
                                        helper.setNotif(
                                            "Inspection",
                                            `${data.workstation.name} - ${data.workstation.uid} is completed`,
                                            "info"
                                        );
                                        break;
                                }
                            } else {
                                //nofification
                                switch (parseInt(data.data_value.job_status)) {
                                    case andon_job_status.START:
                                        helper.setNotif(
                                            "Mesin Counter",
                                            `${data.workstation.name} - ${data.workstation.uid} has started`,
                                            "success"
                                        );

                                        break;
                                    case andon_job_status.HOLD:
                                        helper.setNotif(
                                            "Mesin Counter",
                                            `${data.workstation.name} - ${data.workstation.uid} is on hold`,
                                            "success"
                                        );
                                        break;
                                    case andon_job_status.SETUP:
                                        helper.setNotif(
                                            "Mesin Counter",
                                            `${data.workstation.name} - ${data.workstation.uid} Setup`,
                                            "warning"
                                        );
                                        break;
                                    case andon_job_status.STOP:
                                        helper.setNotif(
                                            "Mesin Counter",
                                            `${data.workstation.name} - ${data.workstation.uid} has Stopped`,
                                            "danger"
                                        );
                                        break;
                                    case andon_job_status.FINISH:
                                        helper.setNotif(
                                            "Mesin Counter",
                                            `${data.workstation.name} - ${data.workstation.uid} is completed`,
                                            "info"
                                        );
                                        break;
                                }
                            }
                        } else {
                            helper.setAlert(response.message, "danger");
                        }
                    },
                    error: function (response) {
                        console.log("remote error : ", response);
                    },
                });
            }, process.env.MIX_JS_DELAY ?? 1024);
        },
    };

    remote.init();
});

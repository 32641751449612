window.andon_inspection_detail = {};
$(document).ready(function () {
    let $page = $("#inspection_detail-page");
    let url = window.location.origin + "/ajax/inspections";
    let register_log_id = window.location.pathname.split("/")[2];

    andon_inspection_detail = {
        init: function () {
            inspection_chart.generateActualRecordChart("init");
            inspection_chart.generateActualNGRecordChart("init");

            $table = $page.find("#inspection_detail-datatable");
            let totalData = 0;
            this.dtTable = $table.DataTable({
                processing: true,
                serverSide: true,
                searching: false,
                lengthChange: false,
                ajax: {
                    url: url,
                    type: "get",
                    data: function (d) {
                        d.mode = "detail_datatable";
                        d.register_log = register_log_id;
                        d.status = $("#filter-status").val();
                    },
                    dataSrc: function (json) {
                        totalData = json.recordsTotal;
                        return json.data;
                    },
                },

                columns: [
                    {
                        data: "DT_RowIndex",
                        name: "DT_RowIndex",
                        orderable: false,
                        searchable: false,
                    },
                    { data: "server_start_time", name: "server_start_time" },
                    {
                        data: "server_start_time_display",
                        name: "server_start_time_display",
                    },
                    { data: "server_finish_time", name: "server_finish_time" },
                    {
                        data: "server_finish_time_display",
                        name: "server_finish_time_display",
                    },
                    { data: "action", name: "action" },
                    { data: "action_display", name: "action_display" },
                    {
                        data: "actual",
                        name: "actual",
                        render: function (data, type, row, meta) {
                            return row.action == 1 ? data : "";
                        },
                    },
                    {
                        data: "type_display",
                        name: "type_display",
                        render: function (data, type, row, meta) {
                            console.log("row", row);
                            return row.action == 1
                                ? row.is_ok == 1
                                    ? `<span class="text-success font-weight-bolder">${data}</span>`
                                    : `<span class="text-danger font-weight-bolder">${data}</span>`
                                : "";
                        },
                    },
                    { data: "duration", name: "duration" },
                    { data: "duration_display", name: "duration_display" },
                ],
                columnDefs: [
                    {
                        targets: 0,
                        render: function (data, type, row) {
                            return totalData - data + 1;
                        },
                    },
                    { targets: "no-sort", orderable: false },
                    { targets: "hidden", visible: false },
                    { targets: "text-center", className: "text-center" },
                    { targets: "text-end", className: "text-end" },
                    { targets: "wrapper-action", className: "wrapper-action" },
                    { targets: [2], orderData: [1] },
                    { targets: [4], orderData: [3] },
                    { targets: [4], orderData: [3] },
                    { targets: [6], orderData: [5] },
                    { targets: [9], orderData: [8] },
                ],
                order: [[0, "desc"]],
            });

            $("#filter-status").on("change", function (e) {
                andon_inspection_detail.dtTable.draw();
            });

            $("#inspection_detail-export_excel").on("click", function (e) {
                $(this).attr(
                    "href",
                    $(this).data("href") +
                        "?type=excel&register_log=" +
                        register_log_id
                );
            });

            $("#inspection_detail-export_csv").on("click", function (e) {
                $(this).attr(
                    "href",
                    $(this).data("href") +
                        "?type=csv&register_log=" +
                        register_log_id +
                        "&status=" +
                        $("#filter-status").val()
                );
            });

            andon_inspection_detail.refresh_counter();
            // $nGtable = $page.find("#runtime_ng_detail-datatable");
            // this.dtNgTable = $nGtable.DataTable({
            //     processing: true,
            //     serverSide: true,
            //     searching: false,
            //     lengthChange: false,
            //     ajax: {
            //         url: url,
            //         type: "POST",
            //         data: function (d) {
            //             d.mode = "detail_ng_datatable";
            //             d.register_log = register_log_id;
            //         },
            //     },
            //     columns: [
            //         {
            //             data: "DT_RowIndex",
            //             name: "DT_RowIndex",
            //             orderable: false,
            //             searchable: false,
            //         },
            //         { data: "server_start_time", name: "server_start_time" },
            //         {
            //             data: "server_start_time_display",
            //             name: "server_start_time_display",
            //         },
            //         { data: "server_finish_time", name: "server_finish_time" },
            //         {
            //             data: "server_finish_time_display",
            //             name: "server_finish_time_display",
            //         },
            //         { data: "actual", name: "actual" },
            //         { data: "duration", name: "duration" },
            //         { data: "duration_display", name: "duration_display" },
            //     ],
            //     columnDefs: [
            //         { targets: "no-sort", orderable: false },
            //         { targets: "hidden", visible: false },
            //         { targets: "text-center", className: "text-center" },
            //         { targets: "text-end", className: "text-end" },
            //         { targets: "wrapper-action", className: "wrapper-action" },
            //         { targets: [2], orderData: [1] },
            //         { targets: [4], orderData: [3] },
            //         { targets: [7], orderData: [6] },
            //     ],
            //     order: [[0, "desc"]],
            // });

            // $("#runtime_ng_detail-export_excel").on("click", function (e) {
            //     $(this).attr(
            //         "href",
            //         $(this).data("href") +
            //             "?type=excel&ng=1&register_log=" +
            //             register_log_id
            //     );
            // });
        },

        refresh_counter: function () {
            let data = {
                id: register_log_id,
                latest_time: $("#rl_total_time_" + register_log_id).attr(
                    "data-latest"
                ),
            };
            andon_realtime_counter.refresh(data);
        },

        refresh: function ($data = null) {
            if ($data !== null && $data !== false) {
                let data = $.parseJSON($data);

                $("#rl_job_id_" + data.register_log.id).text(
                    data.register_log.job_id
                );
                $("#rl_employee_name_" + data.register_log.id).text(
                    data.register_log.employee_name
                );
                $("#rl_ng_ratio_" + data.register_log.id).text(
                    data.register_log.actual_ng +
                        " / " +
                        (data.register_log.actual + data.register_log.actual_ng)
                );
                // $("#rl_performance_" + data.register_log.id).text(
                //     helper.round(data.register_log.performance, 2) + "%"
                // );
                $("#rl_job_status_" + data.register_log.id).attr(
                    "data-value",
                    data.data_value.job_status
                );

                $("#rl_actual_" + data.register_log.id).text(
                    data.register_log.actual + data.register_log.actual_ng
                );
                $("#rl_actual_ok_" + data.register_log.id).text(
                    data.register_log.actual
                );
                $("#rl_actual_ok_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.actual
                );
                $("#rl_actual_ng_" + data.register_log.id).text(
                    data.register_log.actual_ng
                );
                $("#rl_actual_ng_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.actual_ng
                );

                let run_production_time = new Date(0);
                // console.log("Run prod time : ", run_production_time);
                run_production_time.setMilliseconds(
                    data.register_log.run_production_time
                );
                let run_production_time_string = run_production_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");

                // console.log(
                //     "Run prod time STRING : ",
                //     run_production_time_string
                // );

                $("#rl_run_production_time_" + data.register_log.id).text(
                    run_production_time_string
                );
                $("#rl_run_production_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.run_production_time
                );
                let non_production_time = new Date(0);

                // console.log("non_production_time : ", non_production_time);
                non_production_time.setMilliseconds(
                    data.register_log.non_production_time
                );
                let non_production_time_string = non_production_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_non_production_time_" + data.register_log.id).text(
                    non_production_time_string
                );
                $("#rl_non_production_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.non_production_time
                );
                let total_time = new Date(0);
                total_time.setMilliseconds(data.register_log.total_time);
                let total_time_string = total_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_total_time_" + data.register_log.id).text(
                    total_time_string
                );
                $("#rl_total_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.total_time
                );
                $("#rl_total_time_" + data.register_log.id).attr(
                    "data-latest",
                    data.data_value.server_time
                );

                let average_hold_time = new Date(0);
                average_hold_time.setMilliseconds(
                    data.register_log.average_hold_time
                );
                let average_hold_time_string = average_hold_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_average_hold_time_" + data.register_log.id).text(
                    average_hold_time_string
                );
                $("#rl_average_hold_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.average_hold_time
                );
                let average_setup_time = new Date(0);
                average_setup_time.setMilliseconds(
                    data.register_log.average_setup_time
                );
                let average_setup_time_string = average_setup_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_average_setup_time_" + data.register_log.id).text(
                    average_setup_time_string
                );
                $("#rl_average_setup_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.average_setup_time
                );
                let average_stop_time = new Date(0);
                average_stop_time.setMilliseconds(
                    data.register_log.average_stop_time
                );
                let average_stop_time_string = average_stop_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_average_stop_time_" + data.register_log.id).text(
                    average_stop_time_string
                );
                $("#rl_average_stop_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.average_stop_time
                );
                let average_cycle_time = new Date(0);
                average_cycle_time.setMilliseconds(
                    data.register_log.average_cycle_time
                );
                let average_cycle_time_string = average_cycle_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                // console.log(
                //     "average_cycle_time_string : ",
                //     average_cycle_time_string
                // );
                $("#rl_average_cycle_time_" + data.register_log.id).text(
                    average_cycle_time_string
                );
                $("#rl_average_cycle_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.average_cycle_time
                );

                $("#rl_run_" + data.register_log.id).text(
                    data.register_log.run
                );
                $("#rl_run_time_" + data.register_log.id).text(
                    run_production_time_string
                );
                $("#rl_run_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.run_production_time
                );
                $("#rl_hold_" + data.register_log.id).text(
                    data.register_log.hold
                );
                $("#rl_hold_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.hold
                );
                let hold_time = new Date(0);
                hold_time.setMilliseconds(data.register_log.hold_time);
                let hold_time_string = hold_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_hold_time_" + data.register_log.id).text(
                    hold_time_string
                );
                $("#rl_hold_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.hold_time
                );
                $("#rl_setup_" + data.register_log.id).text(
                    data.register_log.setup
                );
                $("#rl_setup_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.setup
                );
                let setup_time = new Date(0);
                setup_time.setMilliseconds(data.register_log.setup_time);
                let setup_time_string = setup_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_setup_time_" + data.register_log.id).text(
                    setup_time_string
                );
                $("#rl_setup_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.setup_time
                );
                $("#rl_stop_" + data.register_log.id).text(
                    data.register_log.stop
                );
                $("#rl_stop_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.stop
                );
                let stop_time = new Date(0);
                stop_time.setMilliseconds(data.register_log.stop_time);
                let stop_time_string = stop_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_stop_time_" + data.register_log.id).text(
                    stop_time_string
                );
                $("#rl_stop_time_" + data.register_log.id).attr(
                    "data-value",
                    data.register_log.stop_time
                );

                let rl_bg_color = "";
                let rl_toggle_icon = "";
                let rl_job_status = "";
                switch (parseInt(data.data_value.job_status)) {
                    case andon_job_status.IDLE:
                        rl_bg_color = "bg-info";
                        rl_toggle_icon = "fa-toggle-on";
                        rl_job_status = "Idle";
                        break;
                    case andon_job_status.START:
                        rl_bg_color = "bg-success";
                        rl_toggle_icon = "fa-toggle-on";
                        rl_job_status = "Run";
                        break;
                    case andon_job_status.HOLD:
                        rl_bg_color = "bg-secondary";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Hold";
                        break;
                    case andon_job_status.SETUP:
                        rl_bg_color = "bg-warning";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Setup";
                        break;
                    case andon_job_status.STOP:
                        rl_bg_color = "bg-danger";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Stop";
                        break;
                    case andon_job_status.FINISH:
                        rl_bg_color = "bg-primary";
                        rl_toggle_icon = "fa-toggle-off";
                        rl_job_status = "Complete";
                        break;
                }
                let icon_color = $("#rl_bg_color_" + data.register_log.id);
                icon_color.removeClass(
                    "bg-info bg-success bg-secondary bg-warning bg-danger"
                );
                icon_color.addClass(rl_bg_color);
                let icon_logo = $("#rl_toggle_icon_" + data.register_log.id);
                icon_logo.removeClass("fa-toggle-on fa-toggle-off");
                icon_logo.addClass(rl_toggle_icon);
                let job_status = $("#rl_job_status_" + data.register_log.id);
                job_status.text(rl_job_status);
                job_status.attr("data-value", data.data_value.job_status);
                if (
                    parseInt(data.data_value.job_status) ===
                    andon_job_status.FINISH
                ) {
                    job_status.removeClass("custom-blink");
                }

                andon_inspection_detail.dtTable.draw();
                // andon_inspection_detail.dtNgTable.draw();
                inspection_chart.generateActualRecordChart("update");
                inspection_chart.generateActualNGRecordChart("update");

                andon_inspection_detail.refresh_counter();
            }
        },

        refresh_part: function (data = null) {
            let job_status = parseInt(
                $("#rl_job_status_" + data.count.id).attr("data-value")
            );

            if (
                data !== null &&
                data !== false &&
                job_status !== andon_job_status.FINISH
            ) {
                if (job_status === andon_job_status.START) {
                    let old_run_production_time = $(
                        "#rl_run_production_time_" + data.count.id
                    ).attr("data-value")
                        ? parseInt(
                              $(
                                  "#rl_run_production_time_" + data.count.id
                              ).attr("data-value")
                          )
                        : 0;

                    let new_run_production_time =
                        old_run_production_time + data.count.diff;

                    let run_production_time = new Date(0);
                    run_production_time.setMilliseconds(
                        new_run_production_time
                    );

                    let run_production_time_string = run_production_time
                        .toISOString()
                        .substr(11, 8)
                        .replace(/\:/g, " : ");

                    $("#rl_run_production_time_" + data.count.id).text(
                        run_production_time_string
                    );

                    $("#rl_run_time_" + data.count.id).text(
                        run_production_time_string
                    );

                    let actual = $("#rl_actual_ok_" + data.count.id).attr(
                        "data-value"
                    )
                        ? parseInt(
                              $("#rl_actual_ok_" + data.count.id).attr(
                                  "data-value"
                              )
                          )
                        : 0;
                    let old_average_cycle_time = $(
                        "#rl_average_cycle_time_" + data.count.id
                    ).attr("data-value")
                        ? parseInt(
                              $("#rl_average_cycle_time_" + data.count.id).attr(
                                  "data-value"
                              )
                          )
                        : 0;
                    let new_average_cycle_time = actual
                        ? helper.round(new_run_production_time / actual, 2)
                        : helper.round(old_average_cycle_time, 2);
                    let average_cycle_time = new Date(0);
                    average_cycle_time.setMilliseconds(new_average_cycle_time);
                    let average_cycle_time_string = average_cycle_time
                        .toISOString()
                        .substr(11, 8)
                        .replace(/\:/g, " : ");
                    $("#rl_average_cycle_time_" + data.count.id).text(
                        average_cycle_time_string
                    );
                } else {
                    let old_non_production_time = $(
                        "#rl_non_production_time_" + data.count.id
                    ).attr("data-value")
                        ? parseInt(
                              $(
                                  "#rl_non_production_time_" + data.count.id
                              ).attr("data-value")
                          )
                        : 0;
                    let new_non_production_time =
                        old_non_production_time + data.count.diff;
                    let non_production_time = new Date(0);
                    non_production_time.setMilliseconds(
                        new_non_production_time
                    );
                    let non_production_time_string = non_production_time
                        .toISOString()
                        .substr(11, 8)
                        .replace(/\:/g, " : ");
                    $("#rl_non_production_time_" + data.count.id).text(
                        non_production_time_string
                    );

                    if (job_status === andon_job_status.HOLD) {
                        let old_hold_time = $(
                            "#rl_hold_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $("#rl_hold_time_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let new_hold_time = old_hold_time + data.count.diff;
                        let hold_time = new Date(0);
                        hold_time.setMilliseconds(new_hold_time);
                        let hold_time_string = hold_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_hold_time_" + data.count.id).text(
                            hold_time_string
                        );

                        let hold = $("#rl_hold_" + data.count.id).attr(
                            "data-value"
                        )
                            ? parseInt(
                                  $("#rl_hold_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let old_average_hold_time = $(
                            "#rl_average_hold_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $(
                                      "#rl_average_hold_time_" + data.count.id
                                  ).attr("data-value")
                              )
                            : 0;
                        let new_average_hold_time = hold
                            ? helper.round(new_hold_time / hold, 2)
                            : helper.round(old_average_hold_time, 2);
                        let average_hold_time = new Date(0);
                        average_hold_time.setMilliseconds(
                            new_average_hold_time
                        );
                        let average_hold_time_string = average_hold_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_average_hold_time_" + data.count.id).text(
                            average_hold_time_string
                        );
                    }

                    if (job_status === andon_job_status.SETUP) {
                        let old_setup_time = $(
                            "#rl_setup_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $("#rl_setup_time_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let new_setup_time = old_setup_time + data.count.diff;
                        let setup_time = new Date(0);
                        setup_time.setMilliseconds(new_setup_time);
                        let setup_time_string = setup_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_setup_time_" + data.count.id).text(
                            setup_time_string
                        );

                        let setup = $("#rl_setup_" + data.count.id).attr(
                            "data-value"
                        )
                            ? parseInt(
                                  $("#rl_setup_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let old_average_setup_time = $(
                            "#rl_average_setup_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $(
                                      "#rl_average_setup_time_" + data.count.id
                                  ).attr("data-value")
                              )
                            : 0;
                        let new_average_setup_time = setup
                            ? helper.round(new_setup_time / setup, 2)
                            : helper.round(old_average_setup_time, 2);
                        let average_setup_time = new Date(0);
                        average_setup_time.setMilliseconds(
                            new_average_setup_time
                        );
                        let average_setup_time_string = average_setup_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_average_setup_time_" + data.count.id).text(
                            average_setup_time_string
                        );
                    }

                    if (job_status === andon_job_status.STOP) {
                        let old_stop_time = $(
                            "#rl_stop_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $("#rl_stop_time_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let new_stop_time = old_stop_time + data.count.diff;
                        let stop_time = new Date(0);
                        stop_time.setMilliseconds(new_stop_time);
                        let stop_time_string = stop_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_stop_time_" + data.count.id).text(
                            stop_time_string
                        );

                        let stop = $("#rl_stop_" + data.count.id).attr(
                            "data-value"
                        )
                            ? parseInt(
                                  $("#rl_stop_" + data.count.id).attr(
                                      "data-value"
                                  )
                              )
                            : 0;
                        let old_average_stop_time = $(
                            "#rl_average_stop_time_" + data.count.id
                        ).attr("data-value")
                            ? parseInt(
                                  $(
                                      "#rl_average_stop_time_" + data.count.id
                                  ).attr("data-value")
                              )
                            : 0;
                        let new_average_stop_time = stop
                            ? helper.round(new_setup_time / stop, 2)
                            : helper.round(old_average_stop_time, 2);
                        let average_stop_time = new Date(0);
                        average_stop_time.setMilliseconds(
                            new_average_stop_time
                        );
                        let average_stop_time_string = average_stop_time
                            .toISOString()
                            .substr(11, 8)
                            .replace(/\:/g, " : ");
                        $("#rl_average_stop_time_" + data.count.id).text(
                            average_stop_time_string
                        );
                    }
                }

                let old_total_time = $("#rl_total_time_" + data.count.id).attr(
                    "data-value"
                )
                    ? parseInt(
                          $("#rl_total_time_" + data.count.id).attr(
                              "data-value"
                          )
                      )
                    : 0;
                let new_total_time = old_total_time + data.count.diff;
                let total_time = new Date(0);
                total_time.setMilliseconds(new_total_time);
                let total_time_string = total_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#rl_total_time_" + data.count.id).text(total_time_string);
            }
        },
    };

    if ($page.length) {
        andon_inspection_detail.init();
    }
});

var workstation = {};
$(document).ready(function () {
    let $page = $('#workstation-page'),
        $table = {};

    workstation = {
        dtTable: {},

        init: function () {
            $table = $page.find('#workstation-datatable');
            this.dtTable = $table.DataTable({
                "processing": true,
                "serverSide": true,
                "searching": false,
                "lengthChange": false,
                "ajax": {
                    url: "/ajax/workstation",
                    type: "POST",
                    data: function (d) { 
                        d.mode = 'datatable';
                        d.production_line = $("#filter-production_line").val();
                        d.status = $("#filter-status").val();
                        d.keyword = $("#filter-keyword").val();
                    }
                },
                "columns": [
                    { data: 'id', name: 'id' },
                    { data: 'name', name: 'name' },
                    { data: 'uid', name: 'uid' },
                    { data: 'uid_ng', name: 'uid_ng' },
                    { data: 'production_line_name', name: 'production_line_name' },
                    { data: 'status', name: 'status' },
                    { data: 'status_display', name: 'status_display' },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'created_at_display', name: 'created_at_display' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'updated_at_display', name: 'updated_at_display' },
                    { data: 'action', name: 'action' }
                ],
                "columnDefs": [
                    { targets: 'no-sort', orderable: false },
                    { targets: 'hidden', visible: false },
                    { targets: 'text-center', className: 'text-center' },
                    { targets: 'text-end', className: 'text-end' },
                    { targets: 'wrapper-action', className: 'wrapper-action' },
                    { targets: [ 6 ], orderData: [ 5 ] },
                    { targets: [ 8 ], orderData: [ 7 ] },
                    { targets: [ 10 ], orderData: [ 9 ] },
                ],
            });

            $table.on('click', '.btn-delete', function (e) {
                e.preventDefault();
                rowId = $(this).attr('data-id');
                helper.deleteConfirm('Are you sure to delete this data?', workstation.deleteData, rowId);
            });

            $('#filter-production_line, #filter-status').on('change', function(e) {
                workstation.dtTable.draw();
            });

            $('#filter-keyword').on('keydown', function(e) {
                if (e.which == 13) {
                    e.preventDefault();
                    workstation.dtTable.draw();
                }
            });

            $("#btn-filter").on('click', function (e) {
                workstation.dtTable.draw();
            });
        },

        deleteData: function (rowId) {
            $.ajax({
                url: '/workstation/'+rowId,
                type: 'DELETE',
                dataType: 'json',
                success: function(response) {
                    workstation.dtTable.draw();
                    if (response.status) {
                        helper.setAlert(response.message, 'success');
                    } else {
                        helper.setAlert(response.message, 'warning');
                    }
                },
                error: function(response) {
                    console.log(response);
                }
            });
        },

        searchProductionLine: function () {
            $select2ProductionLine = $('select[name="production_line_id"]');
            $select2ProductionLine.select2({
                allowClear: true,
                placeholder: 'Search by Production Line Name or ID',
                ajax: {
                    url: '/ajax/production_line',
                    dataType : 'json',
                    delay : 200,
                    data: function (params) {
                        var query = {
                            mode: 'search',
                            search: params.term,
                            page: params.page
                        }
                        return query;
                    },
                    processResults : function(data, params){
                        params.page = params.page || 1;
                        results = data.data.map(function (item) {
                            return {
                                id: item.id,
                                text: item.name,
                            }
                        });
                        return {
                            results: results,
                            pagination:{
                                more : (params.page  * 20) < data.total
                            }
                        };
                    }
                }
            });
        }
        
    };

    if ($page.length) {
        workstation.init();
        workstation.searchProductionLine();
    } 
});
window.andon_dashboard = {};

const no_workstation_element = `<div class="text-center" id="no-workstation">Tidak ada workstation</div>`;

$(document).ready(function () {
    andon_dashboard = {
        updateTotalMain: function (workstation, status) {
            let workstation_card = $("#workstation_" + workstation.id);
            let $total_nonactive = $(".total-nonactive")[0];
            let $total_active = $(".total-active")[0];

            let total_active = parseInt($total_active.innerText);
            let total_nonactive = parseInt($total_nonactive.innerText);

            let $card_nonactive = $(`#card-nonactive`);
            let $card_active = $(`#card-active`);

            if (status == andon_job_status.START) {
                if (total_active == 0) {
                    $card_active.html(workstation_card);
                } else {
                    workstation_card.appendTo($card_active);
                }
                
                total_active = $card_active.children().length;
                total_nonactive = $card_nonactive.children().length;

                if (total_nonactive == 0) {
                    $card_nonactive.html(no_workstation_element);
                }
            }

            if (status == andon_job_status.FINISH) {
                if (total_nonactive == 0) {
                    $card_nonactive.html(workstation_card);
                } else {
                    workstation_card.appendTo($card_nonactive);
                }

                total_active = $card_active.children().length;
                total_nonactive = $card_nonactive.children().length;

                if (total_active == 0) {
                    $card_active.html(no_workstation_element);
                }
            }           

            $total_active.innerHTML = total_active;
            $total_nonactive.innerHTML = total_nonactive;
        },
        updateTotalSub: function (workstation, status) {
            let $total_active = $(
                `.total-active-${workstation.production_line_id}`
            )[0];
            let $total_nonactive = $(
                `.total-nonactive-${workstation.production_line_id}`
            )[0];
            let $workstation_card = $("#workstation_" + workstation.id);

            if (
                $total_nonactive == undefined ||
                $total_active == undefined ||
                $workstation_card == undefined
            ) {
                return;
            }

            let total_active = parseInt($total_active.innerText);
            let total_nonactive = parseInt($total_nonactive.innerText);

            let $card_nonactive = $(
                `#card-nonactive-${workstation.production_line_id}`
            );
            let $card_active = $(
                `#card-active-${workstation.production_line_id}`
            );

            if (status == andon_job_status.START) {
                if (total_active == 0) {
                    $card_active.html($workstation_card);
                } else {
                    $workstation_card.appendTo($card_active);
                }

                total_active = $card_active.children().length;
                total_nonactive = $card_nonactive.children().length;

                if (total_nonactive == 0) {
                    $card_nonactive.html(no_workstation_element);
                }
            }

            if (status == andon_job_status.FINISH) {
                if (total_nonactive == 0) {
                    $card_nonactive.html($workstation_card);
                } else {
                    $workstation_card.appendTo($card_nonactive);
                }

                total_active = $card_active.children().length;
                total_nonactive = $card_nonactive.children().length;

                if (total_active == 0) {
                    $card_active.html(no_workstation_element);
                }
            }

            $total_active.innerHTML = total_active;
            $total_nonactive.innerHTML = total_nonactive;
        },
        refresh: function ($data = null, $sub = false, $skipUpdateTotal = null) {
            if ($data !== null && $data !== false) {
                let data = $.parseJSON($data);
                let icon_color = $(
                    "#workstation_bg_color_" + data.workstation.id
                );
                let workstation_bg_color = "";
                let workstation_toggle_icon = "fa-toggle-off";

                switch (parseInt(data.data_value.job_status)) {
                    case andon_job_status.START:
                        workstation_bg_color = "bg-success";
                        workstation_toggle_icon = "fa-toggle-on";                    

                        if ($sub) {
                            andon_dashboard.updateTotalSub(
                                data.workstation,
                                andon_job_status.START
                            );
                        } else {
                            andon_dashboard.updateTotalMain(
                                data.workstation,
                                andon_job_status.START
                            );
                        }
                        break;
                    case andon_job_status.HOLD:
                        workstation_bg_color = "bg-secondary";
                        workstation_toggle_icon = "fa-toggle-off";
                        break;
                    case andon_job_status.SETUP:
                        workstation_bg_color = "bg-warning";
                        workstation_toggle_icon = "fa-toggle-off";
                        break;
                    case andon_job_status.STOP:
                        workstation_bg_color = "bg-danger";
                        workstation_toggle_icon = "fa-toggle-off";
                        break;
                    case andon_job_status.FINISH:
                        workstation_bg_color = "bg-info";
                        workstation_toggle_icon = "fa-toggle-off";                  

                        if ($sub) {
                            andon_dashboard.updateTotalSub(
                                data.workstation,
                                andon_job_status.FINISH
                            );
                        } else {
                            andon_dashboard.updateTotalMain(
                                data.workstation,
                                andon_job_status.FINISH
                            );
                        }

                        $("#workstation_button_" + data.workstation.id).trigger(
                            "click"
                        );
                        break;
                }

                icon_color.removeClass(
                    "bg-info bg-success bg-secondary bg-warning bg-danger"
                );
                icon_color.addClass(workstation_bg_color);

                let icon_logo = $(
                    "#workstation_toggle_icon_" + data.workstation.id
                );
                icon_logo.removeClass("fa-toggle-on fa-toggle-off");
                icon_logo.addClass(workstation_toggle_icon);
                icon_logo.attr("data-value", data.data_value.job_status);

                $("#workstation_name_" + data.workstation.id).text(
                    data.workstation.name
                );
                $("#workstation_uid_" + data.workstation.id).text(
                    data.workstation.uid
                );

                if ($sub) {
                    $("#workstation_name_uid_" + data.workstation.id).text(
                        data.workstation.name + " - " + data.workstation.uid
                    );

                    let workstation_on =
                        parseInt(data.register_log.status) ===
                        andon_job_status.START;

                    $("#workstation_employee_name_" + data.workstation.id).text(
                        workstation_on ? data.register_log.employee_name : "-"
                    );
                    $("#workstation_job_id_" + data.workstation.id).text(
                        workstation_on ? data.register_log.job_id : "-"
                    );
                    $("#workstation_hold_" + data.workstation.id).text(
                        workstation_on ? data.register_log.hold : 0
                    );
                    $("#workstation_setup_" + data.workstation.id).text(
                        workstation_on ? data.register_log.setup : 0
                    );
                    $("#workstation_stop_" + data.workstation.id).text(
                        workstation_on ? data.register_log.stop : 0
                    );

                    let progress_percentage = 0;
                    let progress_text = "<b>0</b>/0";
                    if (workstation_on) {
                        progress_percentage = data.register_log.target
                            ? helper.round(
                                  (data.register_log.actual /
                                      data.register_log.target) *
                                      100,
                                  2
                              )
                            : 0;
                        progress_text =
                            "<b>" +
                            data.register_log.actual +
                            "</b>/" +
                            data.register_log.target;

                        $("#workstation_url_" + data.workstation.id).attr(
                            "href",
                            data.register_log.view_url
                        );
                    } else {
                        $("#workstation_url_" + data.workstation.id).attr(
                            "href",
                            "#"
                        );
                    }

                    $(
                        "#workstation_progress_percentage_" +
                            data.workstation.id
                    ).text(progress_percentage + "%");
                    $("#workstation_progress_text_" + data.workstation.id).html(
                        progress_text
                    );

                    let bar = $(
                        "#workstation_progress_bar_" + data.workstation.id
                    );
                    bar.removeClass(
                        "bg-info bg-success bg-secondary bg-warning bg-danger"
                    );
                    bar.addClass(workstation_bg_color);
                    bar.width(helper.round(progress_percentage, 2) + "%");

                    let production_time = new Date(0);
                    production_time.setSeconds(
                        workstation_on ? data.register_log.total_time : 0
                    ); // or run_production_time?
                    let production_time_string = production_time
                        .toISOString()
                        .substr(11, 8)
                        .replace(/\:/g, " : ");
                    $(
                        "#workstation_production_time_" + data.workstation.id
                    ).text(production_time_string);
                    $(
                        "#workstation_production_time_" + data.workstation.id
                    ).attr(
                        "data-value",
                        workstation_on ? data.register_log.total_time : 0
                    );
                    $(
                        "#workstation_production_time_" + data.workstation.id
                    ).attr(
                        "data-latest",
                        workstation_on ? data.data_value.server_time : 0
                    );

                    $("#workstation_card_" + data.workstation.id).attr(
                        "data-value",
                        data.register_log.status
                    );
                    if (
                        workstation_on &&
                        $("#workstation_card_" + data.workstation.id).hasClass(
                            "collapsed-card"
                        )
                    ) {
                        $("#workstation_button_" + data.workstation.id).trigger(
                            "click"
                        );
                    }

                    andon_dashboard.refresh_counter_sub(data);
                }
            }
        },

        refresh_counter_sub: function () {
            $(".card").each(function () {
                if (
                    parseInt($(this).attr("data-value")) ===
                    andon_job_status.START
                ) {
                    let dpt = $(this).find(".workstation-production-time");
                    let data = {
                        id: dpt.attr("data-id"),
                        latest_time: dpt.attr("data-latest"),
                    };
                    andon_realtime_counter.refresh(data);
                }
            });
        },

        refresh_part: function (data = null) {
            let workstation_card = $("#workstation_card_" + data.count.id);
            if (
                data !== null &&
                data !== false &&
                parseInt(workstation_card.attr("data-value")) ===
                    andon_job_status.START
            ) {
                let old_production_time = $(
                    "#workstation_production_time_" + data.count.id
                ).attr("data-value")
                    ? parseInt(
                          $(
                              "#workstation_production_time_" + data.count.id
                          ).attr("data-value")
                      )
                    : 0;
                let new_production_time = old_production_time + data.count.diff;
                let production_time = new Date(0);
                production_time.setMilliseconds(new_production_time);
                let production_time_string = production_time
                    .toISOString()
                    .substr(11, 8)
                    .replace(/\:/g, " : ");
                $("#workstation_production_time_" + data.count.id).text(
                    production_time_string
                );
            }
        },
    };

    if ($("#dashboard_sub-page").length) {
        andon_dashboard.refresh_counter_sub();
    }

    if ($("#dashboard-page").length) {
    }
});

import * as echarts from "echarts";

window.inspection_chart = {};
$(document).ready(function () {
    const page = window.location.pathname.split("/")[1];
    let url = window.location.origin + "/ajax/inspections";
    let register_log_id = window.location.pathname.split("/")[2];

    inspection_chart = {
        initStatusRecord: function () {
            let status_record_chart_canvas =
                document.getElementById("status_pie_chart");
            let status_record_option = {
                tooltip: {},
                color: ["#28a745", "#b2bdaa", "#eb9e34", "#fa3c6b"],
                legend: {
                    orient: "vertical",
                    left: "right",
                },
                label: {
                    show: false,
                },
                labelLine: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.8)",
                            },
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                    },
                ],
            };

            this.status_record_chart = echarts.init(
                status_record_chart_canvas,
                "light"
            );
            this.status_record_chart.setOption(status_record_option);
        },
        updateStatusRecord: function () {
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: {
                    mode: "runtime_job_status",
                    register_log: register_log_id,
                },
                success: function (response) {
                    if (response.length > 0) {
                        let data = [];

                        response.forEach((status_record) => {
                            data.push({
                                value: status_record.data,
                                name: status_record.label,
                            });
                        });

                        inspection_chart.status_record_chart.setOption({
                            series: [
                                {
                                    data: data,
                                },
                            ],
                        });
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },
        initActualRecord: function (data) {
            let actual_record_chart_canvas =
                document.getElementById("actual_line_chart");

            let actual_record_chart_options = {
                legend: {
                    show: true,
                    type: "plain",
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    showContent: true,
                    axisPointer: {
                        type: "line",
                        animation: false,
                        axis: "auto",
                        label: {
                            show: true,
                            precision: "0",
                            formatter: function (params) {
                                // console.log(params.seriesData[0].data.duration);
                            },
                        },
                    },
                    valueFormatter: function (value) {
                        let duration = new Date(0);
                        duration.setMilliseconds(value);
                        let arrayTime = duration
                            .toISOString()
                            .substr(11, 12)
                            .split(":");

                        let stringTime = " ";
                        if (arrayTime[0] != "00") {
                            stringTime +=
                                parseInt(arrayTime[0]) +
                                (arrayTime[0] == 1 ? " hour " : " hours ");
                        }
                        if (arrayTime[1] != "00") {
                            stringTime +=
                                parseInt(arrayTime[1]) +
                                (arrayTime[1] == 1 ? " minute " : " minutes ");
                        }

                        let arrayMillisecond = arrayTime[2].split(".");

                        if (arrayMillisecond[0] != "00") {
                            stringTime +=
                                parseInt(arrayMillisecond[0]) +
                                (arrayMillisecond[0] == 1
                                    ? " second "
                                    : " seconds ");
                        }

                        if (arrayMillisecond[1] != "0") {
                            stringTime +=
                                parseInt(arrayMillisecond[1]) +
                                (arrayMillisecond[1] == 1
                                    ? " millisecond"
                                    : " milliseconds");
                        }

                        return stringTime === " " ? " 0 second" : stringTime;
                    },
                },
                dataset: {
                    dimensions: ["actual", "duration", "is_ok", "server_time"],
                    source: data,
                },
                xAxis: {
                    type: "category",
                    name: "Actual",
                    nameLocation: "center",
                    nameTextStyle: {
                        fontWeight: "bold",
                    },
                    nameGap: 40,
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            // console.log("index", index);
                            // console.log("value", value);
                            return value;
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "Duration",
                    nameLocation: "end",
                    nameTextStyle: {
                        fontWeight: "bold",
                        verticalAlign: "middle",
                    },
                    nameGap: 40,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#aaa", "#ddd"],
                        },
                    },
                    gridIndex: 0,
                    boundaryGap: [0, "10%"],
                    axisLabel: {
                        formatter: function (value, index) {
                            let duration = new Date(0);

                            duration.setMilliseconds(value);
                            let arrayTime = duration
                                .toISOString()
                                .substr(11, 12)
                                .split(".");

                            return `${arrayTime[0]}:${arrayTime[1]}`;
                        },
                    },
                },
                toolbox: {
                    left: "center",
                    itemSize: 15,
                    top: 30,
                    orient: "horizontal",
                    feature: {
                        restore: {
                            show: true,
                            titlle: "Reset Zoom",
                        },
                    },
                },
                series: {
                    type: "bar",
                    smooth: true,
                    name: "Duration",
                    seriesLayoutBy: "row",
                    emphasis: { focus: "series" },
                    itemStyle: {
                        color: function (params) {
                            return params.data.bar_color;
                        },
                    },
                },
                dataZoom: {
                    id: "actual-record-zoom",
                    type: "inside",
                    throttle: 50,
                },
            };
            this.actual_record_chart = echarts.init(
                actual_record_chart_canvas,
                "light"
            );
            this.actual_record_chart.setOption(actual_record_chart_options);
        },
        generateActualRecordChart: function (state) {
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: {
                    mode: "inspection_actual",
                    register_log: register_log_id,
                },
                success: function (response) {
                    console.log("inspection chart : ", response);
                    if (response.length > 0 && state == "init") {
                        inspection_chart.initActualRecord(response);
                    }

                    if (response.length > 0 && state == "update") {
                        console.log("masuk sini juga");
                        inspection_chart.actual_record_chart.setOption({
                            dataset: {
                                source: response,
                            },
                        });
                    }
                },
                error: function (response) {
                    console.log("error: ", response);
                },
            });
        },
        initActualNGRecord: function (data) {
            let actual_ng_line_chart_canvas = document.getElementById(
                "actual_ng_line_chart"
            );

            let actual_ng_record_chart_options = {
                title: {
                    text: "OK / NG",
                    left: "center",
                    textStyle: {
                        color: "#999",
                        fontWeight: "normal",
                        fontSize: 14,
                    },
                },
                series: [
                    {
                        type: "pie",
                        radius: [60, 100],
                        top: 30 + "%",
                        height: "33.33%",
                        left: "center",
                        width: 400,
                        itemStyle: {
                            borderColor: "#fff",
                            borderWidth: 1,
                        },
                        color: ["#28a745", "#fa3c6b"],
                        label: {
                            alignTo: "edge",
                            formatter: "{name|{b}}\n{time|{c} item}",
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: "#999",
                                },
                            },
                        },
                        labelLine: {
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80,
                        },
                        labelLayout: function (params) {
                            const isLeft =
                                params.labelRect.x <
                                inspection_chart.actual_ng_record_chart.getWidth() /
                                    2;
                            const points = params.labelLinePoints;
                            points[2][0] = isLeft
                                ? params.labelRect.x
                                : params.labelRect.x + params.labelRect.width;
                            return {
                                labelLinePoints: points,
                            };
                        },
                        data: data,
                    },
                ],
            };

            this.actual_ng_record_chart = echarts.init(
                actual_ng_line_chart_canvas,
                "light"
            );
            this.actual_ng_record_chart.setOption(
                actual_ng_record_chart_options
            );
        },
        ngData: function (res) {
            return [
                { value: res.actual ?? 0, name: "OK" },
                { value: res.ng_actual ?? 0, name: "NG" },
            ];
        },
        generateActualNGRecordChart: function (state) {
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: {
                    mode: "runtime_actual_ng",
                    register_log: register_log_id,
                },
                success: function (response) {
                    if (response && state == "init") {
                        const data = inspection_chart.ngData(response);
                        inspection_chart.initActualNGRecord(data);
                    }

                    if (response && state == "update") {
                        inspection_chart.actual_ng_record_chart.setOption({
                            series: [
                                {
                                    data: inspection_chart.ngData(response),
                                },
                            ],
                        });
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },
    };
});

var user = {};
$(document).ready(function () {
    let $page = $('#user-page'),
        $table = {};

    user = {
        dtTable: {},
        
        init: function () {
            $table = $page.find('#user-datatable');
            this.dtTable = $table.DataTable({
                "processing": true,
                "serverSide": true,
                "searching": false,
                "lengthChange": false,
                "ajax": {
                    url: "/ajax/user",
                    type: "POST",
                    data: function (d) { 
                        d.mode = 'datatable';
                        d.role = $("#filter-role").val();
                        d.production_line = $("#filter-production_line").val();
                        d.keyword = $("#filter-keyword").val();
                    }
                },
                "columns": [
                    { data: 'id', name: 'id' },
                    { data: 'name', name: 'name' },
                    { data: 'email', name: 'email' },
                    { data: 'role_id', name: 'role_id' },
                    { data: 'role', name: 'role' },
                    { data: 'production_line_name', name: 'production_line_name' },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'created_at_display', name: 'created_at_display' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'updated_at_display', name: 'updated_at_display' },
                    { data: 'action', name: 'action' }
                ],
                "columnDefs": [
                    { targets: 'no-sort', orderable: false },
                    { targets: 'hidden', visible: false },
                    { targets: 'text-center', className: 'text-center' },
                    { targets: 'text-end', className: 'text-end' },
                    { targets: 'wrapper-action', className: 'wrapper-action' },
                    { targets: [ 4 ], orderData: [ 3 ] },
                    { targets: [ 7 ], orderData: [ 6 ] },
                    { targets: [ 9 ], orderData: [ 8 ] },
                ],
            });

            $table.on('click', '.btn-delete', function (e) {
                e.preventDefault();
                rowId = $(this).attr('data-id');
                helper.deleteConfirm('Are you sure to delete this data?', user.deleteData, rowId);
            });

            $("#filter-role, #filter-production_line").on('change', function (e) {
                user.dtTable.draw();
            });

            $('#filter-keyword').on('keydown', function(e) {
                if (e.which == 13) {
                    e.preventDefault();
                    user.dtTable.draw();
                }
            });

            $("#btn-filter").on('click', function (e) {
                user.dtTable.draw();
            });

            $("#role_id").on('change', function (e) {
                if ($(this).val() == 1) { //if Admin
                    $("#production_line-section").hide();
                } else {
                    $("#production_line-section").show();
                }
            });
        },

        deleteData: function (rowId) {
            $.ajax({
                url: '/user/'+rowId,
                type: 'DELETE',
                dataType: 'json',
                success: function(response) {
                    user.dtTable.draw();
                    helper.setAlert(response.message, 'success');
                },
                error: function(response) {
                    console.log(response);
                }
            });
        },

        searchProductionLine: function () {
            $select2ProductionLine = $('select[name="production_line_id"]');
            $select2ProductionLine.select2({
                allowClear: true,
                placeholder: 'Search by Production Line Name or ID',
                ajax: {
                    url: '/ajax/production_line',
                    dataType : 'json',
                    delay : 200,
                    data: function (params) {
                        var query = {
                            mode: 'search',
                            search: params.term,
                            page: params.page
                        }
                        return query;
                    },
                    processResults : function(data, params){
                        params.page = params.page || 1;
                        results = data.data.map(function (item) {
                            return {
                                id: item.id,
                                text: item.name,
                            }
                        });
                        return {
                            results: results,
                            pagination:{
                                more : (params.page  * 20) < data.total
                            }
                        };
                    }
                }
            });
        }
    };

    if ($page.length) {
        user.init();
        user.searchProductionLine();
    } 
});